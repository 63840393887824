const Prod: any = {  
  'issuer': 'https://auth.identity.ace.aaa.com/oauth2/aus12a8cxhOHQlnLH357',
  'clientId': '0oapj4modrrtXPHTh357',
  'scopes': ['profile', 'clubCode', 'scope', 'openid', 'customerId', 'membershipStatus'],
  'remoteLoggerApi': `https://common.api.ace.aaa.com/enterprise-identity/prod/remote-logger/v1`,
    //Anonymous flow.
    'anonymousIssuer': 'https://auth.identity.ace.aaa.com/oauth2/aus16jnn1mwxmedaU358',
    'anonymousClientId': '0oa16jnea6in7koqv358',
    'anonymousScopes': {
      'poi': 'iss.anonymous.poi',
      'efnol': 'iss.anonymous.efnol'
    },
    "anonymousLoginUrl": "https://app.ace.aaa.com/enterprise/identity/prod#/anonymous"
};

export default Prod;