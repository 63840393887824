const Nonprod: any = {  
  'issuer': 'https://nonprod-auth.identity.ace.aaa.com/oauth2/ausmofquo62cHtYNk0h7',
  'clientId': '0oa16zaw04lxU3cpZ0h8',
  'scopes': ['profile', 'clubCode', 'scope', 'openid', 'customerId', 'membershipStatus'],  
  'remoteLoggerApi': `https://common.api.uat.ace.aaa.com/enterprise-identity/uat/remote-logger/v1`,
  //Anonymous flow.
  'anonymousIssuer': 'https://nonprod-auth.identity.ace.aaa.com/oauth2/aus2252muxsrsJc1a0h8',
  'anonymousClientId': '0oa2252hfknayjPt90h8',
  'anonymousScopes': {
    'poi': 'iss.anonymous.poi',
    'efnol': 'iss.anonymous.efnol'
  },
  "anonymousLoginUrl": "https://appuat1.ace.aaa.com/enterprise/identity/uat/#/anonymous"
};

export default Nonprod;