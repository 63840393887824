import { TaggingModel, TaggingSource, ProductType, WebLogLevel } from 'shared';
import { utility, WebUtils } from 'utils';

// to prevent typescript errors
/* eslint @typescript-eslint/no-unused-vars : off */
declare let aceMediaTagValues: any;
declare let utag: any;

// declare aceMediaTagValues global object in index.html
// set values for aceMediaTagValues
// so that utag.js can automatically fire view event after loading...
// <script type="text/javascript" src="//tms.ace.aaa.com/ace/prod/utag.js">
// old library //tags.tiqcdn.com/utag/aaa/ace/prod/utag.js
export const tagApplicationPageLoad = (data: TaggingModel) => {
  try {
    if (!isUtagLoaded()) {
      aceMediaTagValues = {
        channel: data.channel,
        clubcode: data.clubcode,
        bills_due: data.bills_due,
        cta: '',
        flow: data.flow,
        isauthenticated: data.isauthenticated,
        mem_myaccountalert: data.mem_myaccountalert,
        mem_autopay: data.mem_autopay,
        mem_billplan: data.mem_billplan,
        mem_level: data.mem_level,
        mem_role: data.mem_role,
        page: 'my account:manage my account',
        sessionId: data.sessionId,
        customerid: data.customerid,
        state: data.state,
        error: data.error,
      };
      loadUtagJs(0, tagApplicationPageLoad, data);
    } else {
      data.cta = '';
      data.page = 'my account:manage my account';
      // utag.view(data);
    }
  } catch (e) {
    void 0;
  }
};

export const tagMembershipCancelledView = (data: TaggingModel): void => {
  try {
    if (!isUtagLoaded()) {
      aceMediaTagValues = {
        channel: data.channel,
        clubcode: data.clubcode,
        bills_due: ['auto', 'membership'],
        cta: '',
        flow: data.flow,
        isauthenticated: data.isauthenticated,
        mem_myaccountalert: 'payment method failed',
        mem_autopay: data.mem_autopay,
        mem_billplan: data.mem_billplan,
        mem_level: data.mem_level,
        mem_role: data.mem_role,
        page: 'my account:manage my account:error-cancelled membership',
        sessionId: data.sessionId,
        customerid: data.customerid,
        state: data.state,
        error: 'membership has been cancelled',
      };
      loadUtagJs(0, tagMembershipCancelledView, data);
    } else {
      data.bills_due = ['auto', 'membership'];
      data.cta = '';
      data.error = 'membership has been cancelled';
      data.mem_myaccountalert = 'payment method failed';
      data.page = 'my account:manage my account:error-cancelled membership';
      utag.view(data);
    }
  } catch (e) {
    const { aceMeta } = WebUtils.getMetadata();
    WebUtils.logWeb({
      message: 'tagMembershipCancelledView error',
      level: WebLogLevel.error,
      data: WebUtils.constructLogData({ error: e }, data.customerid),
      aceMeta,
    });
  }
};

export const tagMyAccountView = (data: TaggingModel) => {
  try {
    if (utag && data) {
      data.cta = 'my account:manage my account:module:my account';
      data.page = 'my account:manage my account';
      utag.link(data);
    }
  } catch (e) {
    void 0;
  }
};

export const tagSevenDayWait = (data: TaggingModel) => {
  try {
    if (utag && data) {
      data.cta = 'my account:manage my account:7 day wait see details click';
      data.page = 'my account:manage my account';
      utag.link(data);
    }
  } catch (e) {
    void 0;
  }
};

export const tagDiscountMoneySaved = (data: TaggingModel) => {
  try {
    if (utag && data) {
      data.cta = 'my account:manage my account:d&r see more aaa discounts click';
      data.page = 'my account:manage my account';
      utag.link(data);
    }
  } catch (e) {
    void 0;
  }
};

export const tagPMID = (data: TaggingModel) => {
  try {
    if (utag && data) {
      data.cta = 'my account:manage my account:protectmyid click';
      data.page = 'my account:manage my account';
      delete data['tci.event_id'];
      utag.link(data);
    }
  } catch (e) {
    void 0;
  }
};

export const tagPMIDAwareness = (data: TaggingModel) => {
  try {
    if (utag && data) {
      data.cta = 'my account:manage my account:pmid activate now click';
      data.page = 'my account:manage my account';
      delete data['tci.event_id'];
      utag.link(data);
    }
  } catch (e) {
    void 0;
  }
};

export const tagCreditCard = (data: TaggingModel) => {
  try {
    if (utag && data) {
      data.cta = 'my account:manage my account:credit card learn more click';
      data.page = 'my account:manage my account';
      delete data['tci.event_id'];
      utag.link(data);
    }
  } catch (e) {
    void 0;
  }
};

export const tagCancelScheduledPayment = (data: TaggingModel) => {
  try {
    if (utag && data) {
      const cancelScheduledPaymentTag = {
        bills_due: data.bills_due,
        channel: data.channel,
        clubcode: data.clubcode,
        customerid: data.customerid,
        flow: data.flow,
        isauthenticated: data.isauthenticated,
        mem_autopay: data.mem_autopay,
        mem_billplan: data.mem_billplan,
        mem_level: data.mem_level,
        mem_role: data.mem_role,
        page: 'my account:manage my account:cancel scheduled payment',
        sessionid: data.sessionId,
        state: data.state,
        subchannel1: 'manage my account',
      };
      utag.view(cancelScheduledPaymentTag);
    }
  } catch (e) {
    void 0;
  }
};

export const tagCancelScheduledPaymentSuccess = (data: TaggingModel) => {
  try {
    if (utag && data) {
      const cancelScheduledPaymentTag = {
        bills_due: data.bills_due,
        channel: data.channel,
        clubcode: data.clubcode,
        customerid: data.customerid,
        flow: data.flow,
        isauthenticated: data.isauthenticated,
        mem_autopay: data.mem_autopay,
        mem_billplan: data.mem_billplan,
        mem_level: data.mem_level,
        mem_role: data.mem_role,
        page: 'my account:manage my account:cancel scheduled payment success',
        sessionid: data.sessionId,
        state: data.state,
        subchannel1: 'manage my account',
      };
      utag.view(cancelScheduledPaymentTag);
    }
  } catch (e) {
    void 0;
  }
};

export const tagCancelScheduledPaymentFail = (data: TaggingModel) => {
  try {
    if (utag && data) {
      const cancelScheduledPaymentTag = {
        bills_due: data.bills_due,
        channel: data.channel,
        clubcode: data.clubcode,
        customerid: data.customerid,
        flow: data.flow,
        isauthenticated: data.isauthenticated,
        mem_autopay: data.mem_autopay,
        mem_billplan: data.mem_billplan,
        mem_level: data.mem_level,
        mem_role: data.mem_role,
        page: 'my account:manage my account:cancel scheduled payment failure',
        sessionid: data.sessionId,
        state: data.state,
        subchannel1: 'manage my account',
      };
      utag.view(cancelScheduledPaymentTag);
    }
  } catch (e) {
    void 0;
  }
};

export const tagPayBillView = (data: TaggingModel) => {
  try {
    if (utag && data) {
      data.cta = 'my account:manage my account:module:pay bills';
      data.page = 'my account:manage my account';
      utag.link(data);
    }
  } catch (e) {
    void 0;
  }
};

export const tagPayBillOverlay = (data: TaggingModel, productType: string) => {
  try {
    if (utag && data) {
      data.cta = `my account:manage my account:overlay:pay bills:${productType}:make a payment`;
      data.page = 'my account:manage my account';

      utag.link(data);
    }
  } catch (e) {
    void 0;
  }
};

export const tagOverlayOption = (optionURL: string, ctaSource: string, action: string, product: string) => {
  const newParam = `my account:${ctaSource}:${action}-${product}`;

  const resultURL = utility.addQueryStringParams(optionURL, 'intcmp', newParam);
  return resultURL;
};

export const tagProfileView = (data: TaggingModel) => {
  try {
    if (utag && data) {
      data.cta = 'my account:manage my account:module:profile';
      data.page = 'my account:manage my account';

      utag.link(data);
    }
  } catch (e) {
    void 0;
  }
};

export const tagProfilePageLoad = (data: TaggingModel) => {
  try {
    if (!isUtagLoaded()) {
      aceMediaTagValues = {
        channel: data.channel,
        clubcode: data.clubcode,
        bills_due: data.bills_due,
        cta: '',
        flow: data.flow,
        isauthenticated: data.isauthenticated,
        mem_myaccountalert: data.mem_myaccountalert,
        mem_autopay: data.mem_autopay,
        mem_billplan: data.mem_billplan,
        mem_level: data.mem_level,
        mem_role: data.mem_role,
        page: 'my account:profile',
        sessionId: data.sessionId,
        customerid: data.customerid,
        state: data.state,
        error: data.error,
      };
      loadUtagJs(0, tagProfilePageLoad, data);
    } else {
      data.cta = '';
      data.page = 'my account:profile';
      utag.view(data);
    }
  } catch (e) {
    const { aceMeta } = WebUtils.getMetadata();
    WebUtils.logWeb({
      message: 'tagProfilePageLoad error',
      level: WebLogLevel.error,
      data: WebUtils.constructLogData({ error: e }, data.customerid),
      aceMeta,
    });
  }
};

export const tagNotificationCardPayBillUrl = (
  sourceUrl: string,
  ctaSource: string,
  action: string,
  product: string
): string => {
  const newParam = `manage my account:${ctaSource}:${action}-${product}`;
  const resultURL = utility.addQueryStringParams(sourceUrl, 'intcmp', newParam);

  return resultURL;
};

const tagviewBillUrlNoPdf = (sourceUrl: string, productType: ProductType): string => {
  let type = productType.toLocaleLowerCase();
  if (productType === ProductType.PUP) {
    type = 'umbrella';
  }
  const ctaSource = `my account:manage my account:view bill–no ${type} pdf`;
  const resultURL = utility.addQueryStringParams(sourceUrl, 'intcmp', ctaSource);
  return resultURL;
};

export const tagviewBillUrl = (sourceUrl: string, productType: ProductType, isPayTabSelected: boolean): string => {
  const ctaSource: string = isPayTabSelected ? 'bills tab' : 'notification';
  return tagNotificationCardPayBillUrl(sourceUrl, ctaSource, 'pay bill', productType.toLowerCase());
};

export const tagPayBillCallToAction = (data: TaggingModel) => {
  try {
    if (utag && data) {
      data.page = 'my account:manage my account';

      utag.link(data);
    }
  } catch (e) {
    void 0;
  }
};

export const tagGetAutoQuoteUrl = (sourceUrl: string, ctaSource: string): string => {
  const newParam = `my account:manage my account:${ctaSource}:auto`;
  const resultURL = utility.addQueryStringParams(sourceUrl, 'intcmp', newParam);

  return resultURL;
};

export const tagCancelledPolicyPayBillAction = (data: TaggingModel, taggingSource: TaggingSource, value?: string) => {
  try {
    if (utag && data) {
      //policystate set in cancelled policy modal
      data.page = 'manage my account';
      data.channel = 'my account';
      data.isauthenticated = true;

      switch (taggingSource) {
        case TaggingSource.Overlay:
          data.cta = 'my account:manage my account:make a payment:accident question-blade';
          break;
        case TaggingSource.NotificationCard:
          data.cta = 'my account:manage my account:make a payment:accident question-notification card';
          break;
        default:
          break;
      }
      switch (value) {
        case 'yes':
          data.cta = 'my account:manage my account:make a payment:accident question-yes';
          break;
        case 'no':
          data.cta = 'my account:manage my account:make a payment:accident question-no';
          break;
        default:
          break;
      }

      utag.link(data);
    }
  } catch (e) {
    void 0;
  }
};

export const tagRoadsideAssistanceCallToAction = (data: TaggingModel) => {
  try {
    if (utag && data) {
      data.page = 'my account:manage my account';
      data.cta = 'my account:manage my account:roadside assistance click'.toLowerCase();
      utag.link(data);
    }
  } catch (e) {
    void 0;
  }
};

export const tagSafeliteBlade = (data: TaggingModel) => {
  try {
    if (utag && data) {
      data.page = 'my account:manage my account';
      data.cta = 'my account:manage my account:premier windshield repair click';
      utag.link(data);
    }
  } catch (e) {
    void 0;
  }
};

export const tagNonNamedInsuredModal = (data: TaggingModel) => {
  try {
    if (utag && data) {
      data.page = 'my account:manage my account:non named insured restriction modal';
      delete data.cta;
      delete data.error;
      utag.view(data);
    }
  } catch (e) {
    void 0;
  }
};

export const tagErrorModal = (data: TaggingModel, sessionId: string) => {
  try {
    if (isUtagLoaded()) {
      if (utag && data) {
        data.error = 'my account:manage my account:ref id-internal error';
        data.refid = `${sessionId}`;
        data.page = 'my account:manage my account:request not processed:refid error';
        utag.view(data);
      }
    } else {
      aceMediaTagValues = {
        channel: 'my account',
        clubcode: data.clubcode,
        isauthenticated: true,
        page: 'my account:manage my account:request not processed:refid error',
        sessionId: data.sessionId,
        customerid: data.customerid,
        state: data.state,
        error: 'my account:manage my account:ref id-internal error',
        refid: `${sessionId}`,
        bills_due: [],
        cta: '',
        flow: '',
        mem_myaccountalert: '',
        mem_autopay: '',
        mem_billplan: '',
        mem_level: '',
        mem_role: '',
      };
      loadUtagJs(0, tagErrorModal, data, sessionId);
    }
  } catch (e) {
    void 0;
  }
};

export const tagErrorModalLink = (data: TaggingModel, sessionId: string) => {
  try {
    if (isUtagLoaded()) {
      if (utag && data) {
        data.refid = `${sessionId}`;
        utag.link(data);
      }
    } else {
      aceMediaTagValues = { ...data, refId: sessionId };
      loadUtagJs(0, tagErrorModalLink, data, sessionId);
    }
  } catch (e) {
    void 0;
  }
};

export const tagLoginUrlSessionExpired = (loginUrl: string): string => {
  const taggedLoginUrl = utility.addQueryStringParams(loginUrl, 'login', 'False');
  return taggedLoginUrl;
};

export const tagLogoutReturnUrl = (logoutUrl: string, returnUrl: string): string => {
  const taggedReturnUrl: string = utility.addQueryStringParams(returnUrl, 'login', 'True');
  let taggedLogoutUrl: string = utility.addQueryStringParams(logoutUrl, 'returnUrl', taggedReturnUrl);
  const logoutUrlArr = logoutUrl.split('#');
  //append # if any
  if (logoutUrlArr.length > 1) {
    taggedLogoutUrl = utility.addQueryStringParams(logoutUrlArr[0], 'returnUrl', taggedReturnUrl);
    taggedLogoutUrl = `${taggedLogoutUrl}#${logoutUrlArr[1]}`;
  }
  return taggedLogoutUrl;
};

export const tagViewBillPdf = (data: TaggingModel, productType: ProductType) => {
  const result = {
    isSuccessful: false,
    data: '',
  };
  let type = productType.toLocaleLowerCase();
  if (productType === ProductType.PUP) {
    type = 'umbrella';
  }
  try {
    if (utag && data) {
      // data.bills_due = [type, "membership"];
      data.cta = `my account:manage my account:view bill pdf-${type}`;
      data.page = 'my account:manage my account';
      utag.link(data);
      result.isSuccessful = true;
    }
  } catch (err) {
    const { message } = err as Error;
    result.data = message;
    return result;
  }
  return result;
};

export const loadUtagJs = (attempt: number = 0, callback?: Function, ...args: any) => {
  if (isUtagLoaded()) {
    delete (window as any).utagLoading;
    if (callback) callback(...args);
    return true;
  }
  if (attempt === 5) {
    delete (window as any).utagLoading;
    return false;
  }
  if (!(window as any).utagLoading) {
    (window as any).utagLoading = true;
    utility.loadExternalJavascriptLibraries('//tms.ace.aaa.com/ace/prod/utag.js');
  }
  setTimeout(loadUtagJs, 500, attempt + 1, callback, ...args);
};

const isUtagLoaded = () => {
  if (typeof utag !== 'undefined' && typeof utag.link == 'function') {
    //utag.view(errorDataGoesHere);
    return true;
  } else {
    //aceMediaTagValues = errorDataGoesHere;
    //load utag.js
    return false;
  }
};
