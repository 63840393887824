import { CiGift } from 'react-icons/ci';
import { tagDiscountMoneySaved } from './TaggingHelper';

export interface MemberSavingsProps {
  savingsAmount: string;
  discountsUrl: string;
  analyticsModel: any;
}

export const MemberSavingsHint: React.FunctionComponent<MemberSavingsProps> = ({
  savingsAmount,
  discountsUrl,
  analyticsModel,
}: MemberSavingsProps) => {
  const clickHandler = (event) => {
    tagDiscountMoneySaved(analyticsModel);
    event.stopPropagation();
    window.open(discountsUrl, '_blank');
  };
  return (
    <div
      style={{
        padding: '15px',
        margin: '10px 0 25px 0',
        backgroundColor: '#FFF3D6',
        fontSize: '.9em',
        border: 'none',
        textAlign: 'center',
        borderRadius: '10px',
        display: 'flex',
      }}
      data-testid="savings-hint"
    >
      <div style={{ paddingRight: '5px' }}>
        <div
          style={{
            borderRadius: '50%',
            backgroundColor: 'white',
            height: '35px',
            width: '35px',
          }}
        >
          <CiGift color="#A85D00" style={{ height: '35px', width: '25px' }} />
        </div>
      </div>
      <div style={{ paddingTop: '3px', textAlign: 'justify' }}>
        <p>
          As a member, you've saved an estimated ${savingsAmount} with AAA Discounts &amp; Rewards in the last 12
          months.
          <span> </span>
          <span style={{ color: 'blue', textDecoration: 'underline' }} onClick={(e) => clickHandler(e)}>
            See more AAA discounts
          </span>
        </p>
      </div>
    </div>
  );
};
