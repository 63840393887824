import { OktaAuth } from '@okta/okta-auth-js';
import ConfigLoader from './config/Loader';
import Cookies from 'universal-cookie';
declare global {
  interface Window {
    AceIdentity: any;
    AceIdentityEnv: any;
    AceIdentityConfig: any;
  }
}

export enum AceIdentityEnv {
  NonProd = 'non-prod',
  Prod = 'prod',
}

export enum LoginFlow {
  Anonymous = 'anonymous',
  Registered = 'registered'
}

export interface AceIdentityConfig {
  env: string;
  issuer?: string;
  clientId?: string;
  scopes?: string[];
  redirectUri: string;
  loginUrl: string;
  bypassAuth2: boolean;
  disableBypassPkce: boolean;
  searchParams: { query: string, value: string }[];
  loginFlow?: string;
  associatedApp?: string;
  backend?: string;
}

class AceIdentity {
  private authClient;
  private scopes;
  private env;
  private config;
  private opt;
  private issuer;
  private clientId;
  private redirectUri;
  private hiddenIframe: any;
  private bypassAuth2;
  private disableBypassPkce;
  private loginFlow;
  private loginUrl;
  private associatedApp;
  private identityName;
  private backend;

  constructor(opt: AceIdentityConfig) {
    this.opt = opt;
    this.env = opt.env || AceIdentityEnv.NonProd;
    this.config = ConfigLoader.getConfig(this.env);

    this.loginFlow = (opt?.loginFlow || LoginFlow.Registered) as LoginFlow;

    this.validateInputs(this.loginFlow, this.opt);

    this.redirectUri = opt.redirectUri;
    this.disableBypassPkce = opt.disableBypassPkce;

    if (this.loginFlow === LoginFlow.Anonymous) {
      this.identityName = 'identity lib anonymous'; 
      this.associatedApp = opt.associatedApp;
      this.scopes = opt.scopes;
      this.loginUrl = opt.loginUrl || this.config.anonymousLoginUrl;
      this.issuer = this.config.anonymousIssuer;
      this.clientId = this.config.anonymousClientId;
      this.bypassAuth2 = true;

      if (this.env === AceIdentityEnv.NonProd) {
        this.backend = opt.backend || 'A1';
      }
    } else {
      this.identityName = 'identity lib'; 
      this.loginUrl = opt?.loginUrl;
      this.scopes = opt?.scopes || this.config.scopes; 
      this.issuer = opt?.issuer || this.config.issuer;
      this.clientId = opt?.clientId || this.config.clientId;
      this.bypassAuth2 = opt?.bypassAuth2;
    }

    this.authClient = new OktaAuth({
      'issuer': this.issuer,
      'clientId': this.clientId,
      'redirectUri': `${this.redirectUri}/login/callback`
    });
  }

  oktaAuth(): any {
    return this.authClient;
  }

  async ready(): Promise<any> {
    this.hiddenIframe?.parentElement?.removeChild(this.hiddenIframe);
    this.hiddenIframe = document.createElement('iframe');
    this.hiddenIframe.style.display = 'none';
    this.hiddenIframe.src = this.getLogProxyUrl();
    this.hiddenIframe.loading = 'eager';
    const loaded = new Promise((resolve) => {
      this.hiddenIframe.onload = resolve;
    });
    document.body.appendChild(this.hiddenIframe);
    await loaded;
  }

  async tokens(): Promise<any> {
    try {
      this.sendLog(`${this.identityName} - attempt to get tokens`);
      const cookies = new Cookies();
      const aceeckp = cookies.get('aceeckp');
      if (!this.disableBypassPkce && aceeckp) {
        this.sendLog(`${this.identityName} - bypass pkce`);
        const payload = {
          accessToken: aceeckp,
          idToken: ''
        };
        return payload;
      } else {
        this.sendLog(`${this.identityName} - use pkce`);
        const service = await this.authClient.token.getWithoutPrompt({
          scopes: this.scopes
        });
        const tokens = service.tokens;
        this.sendLog(`${this.identityName} - get tokens successfully`);
        const payload = {
          accessToken: tokens?.accessToken?.accessToken,
          idToken: tokens?.idToken?.idToken
        };
        return payload;
      }
    } catch (e: any) {
      if (e.message?.toLowerCase().includes('the client specified not to prompt, but the user is not logged in')) {
        this.sendLog(`${this.identityName} - failed to get tokens due to not logged in`);
        const loginUrlObj = this.loginUrl.split('#');
        if (loginUrlObj.length > 1) {
          this.sendLog(`${this.identityName} - redirect to login`);

          const redirectUrl = new URL(loginUrlObj[0]);
          redirectUrl.searchParams.append("returnUrl", this.opt.redirectUri);
          if(this.loginFlow === LoginFlow.Anonymous) {
            redirectUrl.searchParams.append("zr_noredirect", 'true');
            if(this.associatedApp) {
              redirectUrl.searchParams.append("associatedApp", this.associatedApp);
            } else {
              this.sendLog(`${this.identityName} - missing associatedApp`);
              throw(`${this.identityName} - missing associatedApp`);
            }
            if (this.backend) {
              redirectUrl.searchParams.append("backend", this.backend);
            }
          }
          redirectUrl.searchParams.append("pkce", "true");
          if (this.bypassAuth2) {
            redirectUrl.searchParams.append("bypassAuth2", "true");
          }
          if (this.opt.searchParams?.length > 0) {
            for (let param of this.opt.searchParams) {
              redirectUrl.searchParams.append(param.query, param.value);
            }
          }
          redirectUrl.hash = loginUrlObj[1];

          setTimeout(() => {
            window.location.assign(redirectUrl);
          }, 1000);
        } else {
          this.sendLog(`${this.identityName} - failed to redirect to login`);
        }
      } else {
        this.sendLog(`${this.identityName} - failed to get tokens due to ${e.message}`);
      }
      return undefined;
    }
  }

  private getLogProxyUrl(): string | undefined {
    let logproxyUrl;
    const loginUrlObj = this.loginUrl.split('#');
    if (loginUrlObj.length > 1) {
      logproxyUrl = `${loginUrlObj[0]}#logproxy`;
    } else {
      console.log('failed to get logproxy url');
    }
    return logproxyUrl;
  }

  private sendLog(msg: any, data?: { [key: string]: any }) {
    if (this.env !== AceIdentityEnv.Prod) { //don't log to console for prod
      console.log(msg, data);
    }
    const logproxyUrl = this.getLogProxyUrl();
    if (logproxyUrl) {
      const dataNew = {
        msg,
        'issuer': this.issuer,
        'clientId': this.clientId,
        'redirectUri': this.redirectUri,
        ...data
      }
      this.hiddenIframe.contentWindow.postMessage(dataNew, logproxyUrl);
    } else {
      console.log('failed to send logproxy');
    }
  }

  private validateInputs(loginFlow: LoginFlow, options: AceIdentityConfig): void {
    switch (loginFlow) {
      case LoginFlow.Anonymous: {
        if (!options.associatedApp) {
          throw new Error('Associated app is required');
        }
        if (!options.scopes) {
          throw new Error('Scopes is required');
        }
        if (!options.redirectUri) {
          throw new Error('Redirect Uri is required');
        }
        break;
      }
      case LoginFlow.Registered: {
        if (!options.redirectUri) {
          throw new Error('Redirect Uri is required');
        }
        break;
      }
      default: {
        throw new Error(`Unsupported login flow: ${loginFlow}`);
      }
    }
  }
}

window.AceIdentity = AceIdentity;
window.AceIdentityEnv = AceIdentityEnv;

export default AceIdentity;
